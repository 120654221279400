import React, { Component } from 'react';
import './App.css';
import './Markdown.css';
import { Layout } from 'antd';
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import 'antd/dist/antd.css';
import Onboard from './onboard/Onboard';
import Console from './console/Console';
import Admin from './admin/Admin';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Layout className="WholePane">
            <Switch>
              <Route path="/onboard" render={() => <Onboard />} />
              <Route path="/console" render={() => <Console />} />
              <Route path="/admin" render={() => <Admin />} />
              <Redirect from="/" to="/onboard" />
            </Switch>
          </Layout>
        </div>
      </Router>
    );
  }
}

export default App;
